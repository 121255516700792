
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/image-compressor",
      function () {
        return require("private-next-pages/image-compressor/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/image-compressor"])
      });
    }
  