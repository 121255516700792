export interface IInfoIconProps {
    fill?: string;
}

export const InfoIcon = ({ fill = "white" }: IInfoIconProps) => {
    return (
        <svg width="6" height="15" viewBox="0 0 6 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.99968 2.29037C3.63214 2.29037 4.14486 1.77765 4.14486 1.14519C4.14486 0.512717 3.63214 0 2.99968 0C2.36721 0 1.85449 0.512717 1.85449 1.14519C1.85449 1.77765 2.36721 2.29037 2.99968 2.29037Z"
                fill={fill}
            />
            <path
                d="M0.512118 5.75489C1.25102 5.42312 1.8547 5.549 1.8547 6.48876V12.7657C1.8547 14.0195 3.29017 14.3283 4.3835 13.6521C4.95187 13.2847 5.45252 12.8219 5.86336 12.2841C5.90415 12.2294 5.92551 12.1626 5.92406 12.0944C5.92261 12.0262 5.89843 11.9604 5.85536 11.9074C5.81229 11.8545 5.75279 11.8174 5.68628 11.8021C5.61977 11.7868 5.55005 11.7942 5.48818 11.823C4.74928 12.1547 4.1456 12.0288 4.1456 11.0891V4.81211C4.1456 3.5583 2.71013 3.24953 1.6168 3.92573C1.04843 4.29313 0.547784 4.75594 0.136934 5.29374C0.0961504 5.34846 0.0747915 5.41523 0.0762411 5.48346C0.0776906 5.55169 0.101866 5.61749 0.144937 5.67043C0.188007 5.72336 0.247511 5.76042 0.314021 5.77572C0.380532 5.79102 0.450245 5.78369 0.512118 5.75489Z"
                fill={fill}
            />
        </svg>
    );
};
