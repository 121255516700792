import { Translate } from "components/common/Translate/Translate";
import { TutorialParent } from "../TutorialParent";

export const CompressorTutorial = () => {
    return (
        <TutorialParent>
            <div className="flex flex-col gap-7">
                {/* header */}
                <h2 className="font-semibold whitespace-nowrap sub-heading mb-3 text-center">
                    <Translate keyName="tutorial-title" />
                </h2>
                {/* tutorial points */}
                <ul className="flex flex-col gap-2">
                    <li className="">
                        <Translate keyName="tutorial-1" />
                    </li>
                    <li className="">
                        <Translate keyName="tutorial-2" />
                    </li>
                    <li className="">
                        <Translate keyName="tutorial-3" />
                    </li>
                </ul>
            </div>
        </TutorialParent>
    );
};
