import { translateFunc } from "client/utils/translateFunc";
import { Translate } from "components/common/Translate/Translate";
import { ContentItem } from "../ContentItem/ContentItem";
import { ContentParent } from "../ContentParent";
import { getCDNIcon } from "../ImageResizerContent/ImageResizerContent";
import { ZipIcon } from "components/common/Icons/ZipIcon";
import { TrophyIcon } from "components/common/Icons/TrophyIcon";
import { LightningFastIcon } from "components/common/Icons/LightningFastIcon";
import { ImageFormatsIcon } from "components/common/Icons/ImageFormatsIcon";
import { PrivacyGuaranteedIcon } from "components/common/Icons/PrivacyGuaranteedIcon";
import { HeartIcon } from "components/common/Icons/HeartIcon";

export const CompressorContent = () => {
    return (
        <ContentParent>
            <ContentItem
                title={<Translate keyName="content-quality-title" />}
                description={<Translate keyName="content-quality-sub-title" />}
                icon={<ZipIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-compression-title" />}
                description={<Translate keyName="content-compression-sub-title" />}
                icon={<TrophyIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-use-title" />}
                description={<Translate keyName="content-use-sub-title" />}
                icon={<LightningFastIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-formats-title" />}
                description={<Translate keyName="content-formats-sub-title" />}
                icon={<ImageFormatsIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-privacy-title" />}
                description={<Translate keyName="content-privacy-sub-title" />}
                icon={<PrivacyGuaranteedIcon />}
            />
            <ContentItem
                title={<Translate keyName="content-free-title" />}
                description={<Translate keyName="content-free-sub-title" />}
                icon={<HeartIcon />}
            />
        </ContentParent>
    );
};
