import { UploadPageLayout } from "components/modules/Layout/UploadPageLayout/UploadPageLayout";
import { CompressorContent } from "components/modules/Content/CompressorContent/CompressorContent";
import { CompressorTutorial } from "components/modules/Tutorial/CompressorTutorial/CompressorTutorial";
import { VideoCompressorLink } from "client/components/VideoCompressorLink";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import type { GetStaticProps } from "next";
import { Navbar } from "components/modules/Navigation/Navbar/Navbar";
import Head from "next/head";
import { LanguageProvider } from "client/contexts/LanguageProvider";
import { commonLanguageFiles } from "client/config";
import { translateFunc } from "client/utils/translateFunc";
import { Translate } from "components/common/Translate/Translate";
import dynamic from "next/dynamic";
import React from "react";
import { RouteNames } from "@/RouteNames";

const CompressUploader = dynamic<{}>(() =>
    import("components/modules/Uploader/Compress/CompressUploader").then((mod) => mod.CompressUploader),
);

const Page = () => {
    const title = translateFunc("page-title");
    const description = translateFunc("meta-description");

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="/social.png" />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
            </Head>
            <UploadPageLayout
                navbar={<Navbar url={RouteNames.imageCompressor} />}
                title={<Translate keyName="title" />}
                subTitle={<Translate keyName="sub-title" />}
                uploader={<CompressUploader />}
                content={<CompressorContent />}
                tutorial={<CompressorTutorial />}
                preToolsSlot={<VideoCompressorLink />}
                pageUrl={RouteNames.imageCompressor}
            />
        </>
    );
};

const files = ["compressor-landing", "uploader", ...commonLanguageFiles];

const LanguageWrapper = () => {
    return (
        <LanguageProvider files={files}>
            <Page />
        </LanguageProvider>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
    props: {
        ...(await serverSideTranslations(locale ?? "", files)),
    },
});

export default LanguageWrapper;
