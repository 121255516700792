import { InfoIcon } from "components/common/Icons/InfoIcon";
import { Translate } from "./common/Translate/Translate";

export const VideoCompressorLink = () => {
    return (
        <div className="pb-16 flex items-center justify-center mx-4 text-black dark:text-white">
            <div className="inline-flex gap-4 items-center">
                <div className="w-8 h-8 flex-shrink-0 flex items-center justify-center bg-irBlue-600 dark:bg-darkPrimary-100 rounded-full">
                    <InfoIcon fill="white" />
                </div>
                <div className="">
                    <Translate keyName="video-compressor-outlink-1" />{" "}
                    <Translate
                        keyName="video-compressor-outlink-2"
                        linkRenderer={(label, key) => (
                            <a
                                className="cursor-pointer text-irBlue-600 dark:text-darkPrimary-100 font-semibold"
                                href="https://www.freeconvert.com/video-compressor"
                                target="_blank"
                                rel="noreferrer"
                                key={key}
                            >
                                {label}
                            </a>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
